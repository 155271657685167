import React, {useEffect, useState} from "react";
import logo from "../assets/images/logo.png";
import logoPlaceholder from "../assets/images/login-placeholder.png";
import googleSsoImage from "../assets/images/google-sso-logo.png";
import CustomButton from "../components/CustomButton";
import {signInWithGooglePopup} from "../lib/firebase.util";
import axios from "axios";
import {SessionUser} from "../lib/SessionUser";
import {publish} from "../lib/event";

function Login(props) {
    const googleLogin = async (e) => {
        try {
            const response = await signInWithGooglePopup();
            const token = response._tokenResponse.idToken;
            const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
            const url = `/public/users.json`;
            const data = { name: response.user.displayName, email: response.user.email, access_token: token };
            const apiResponse = (await axiosInstance.post(url, data, {})).data;
            if(!apiResponse.success) {
                document.getElementById('errorScreenText').innerHTML = 'Login not successful';
                document.getElementById('mainContainer').classList.add('error-alert-open');
                setTimeout(() => {
                    document.getElementById('errorScreenText').innerHTML = '';
                    document.getElementById('mainContainer').classList.remove('error-alert-open');
                }, 3000);
            } else {
                document.getElementById('successScreenText').innerHTML = 'Login successful';
                document.getElementById('mainContainer').classList.add('success-alert-open');
                setTimeout(() => {
                    document.getElementById('successScreenText').innerHTML = '';
                    document.getElementById('mainContainer').classList.remove('success-alert-open');
                    SessionUser.updateProfile(apiResponse.data);
                    window.location.href = window.location.origin;
                }, 3000);
            }
        } catch (err) {
            document.getElementById('errorScreenText').innerHTML = 'Login not successful';
            document.getElementById('mainContainer').classList.add('error-alert-open');
            setTimeout(() => {
                document.getElementById('errorScreenText').innerHTML = '';
                document.getElementById('mainContainer').classList.remove('error-alert-open');
            }, 3000);
            console.log(err);
            // setViewState('failure');
            // hideModal();
        }
    }

    return(
        <div id='loginContainer'>
            <img src={logo} id='logoImage' width={122} />
            <img src={logoPlaceholder} id='logoImagePlaceholder'/>
            <h1>Hangout with your friends &<br/>Discover unique experiences</h1>
            <CustomButton clickEvent={googleLogin} buttonId='GoogleSSOButton' className='btn primary-btn google-sso-button'>
                <img src={googleSsoImage} /> Sign in with Google
            </CustomButton>
        </div>
    );
}

Login.headerTempalte = 'none';
export default Login;