import {ShareNetwork} from "@phosphor-icons/react";
import React from "react";
import moment from "moment";
import CustomLink from "./CustomLink";
import CustomButton from "./CustomButton";
import axios from "axios";
import {SessionUser} from "../lib/SessionUser";

const OldCard = function({ event, expired, showDescription, linkMode = event.rsvp ? 'rsvp' : 'external', showMoreDetails = false, eventId = '' }) {

    const shareAction = async (e) => {
        try {
            const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
            const url = 'public/shares.json'
            const headers = SessionUser.isLoggedIn() ? { headers: { 'Authorization': SessionUser.userToken() } } : {};
            const {data, _status} = await axiosInstance.post(url, {id: event.id}, headers);
            const {share_id, user} = data.data;
            const eventDetails = {
                title: event.title,
                text: `${user ? user.name : 'Your friend'} has shared an event with you via PuttingScene`,
                url: window.location.origin + "/share/" + share_id
            };
            if (navigator.share) { navigator.share(eventDetails); }
            else console.log(eventDetails);
            return false;
        } catch (ex) {
            console.log(ex);
            return false;
        }
    }

    const getLocationUrl = (location) => {
        return "https://www.google.com/maps/search/" + location.replace(' ', '+');
    }

    const getEventDate = (eventDate) => {
        const date = moment(eventDate,'YYYY-MM-DDTHH:mm:SS.SSSZ');
        return `${date.format('Do MMM')} @ ${date.format('LT')}`;
    }

    const getLink = () => {
        switch(linkMode) {
            case 'external':
                return event.test_event ? `social_experience/${event.id}` : event.shortened_link;
            case 'rsvp':
                return `social_experience/${event.id}`;
            case 'upcoming':
                return `my_social_experience/${eventId}`;
            case 'none':
                return '';
        }
    }

    return (
        <CustomLink href={getLink()} className="card event-card" linkId="EventOpenLink" redirect={linkMode !== 'none'}>
            <div className={`card-img-top ${expired ? 'expired' : ''}`} style={{backgroundImage: `url(${event.large_image})`}}>
                {
                    navigator.canShare &&
                    <CustomButton className="share-btn" clickEvent={shareAction} buttonId="EventShareButton">
                        <ShareNetwork size="18" />
                    </CustomButton>
                }
            </div>
            <div className="card-body">
                <h5 className="card-title">{event.title}</h5>
                {
                    showDescription &&
                    <p className="card-text">{event.short_description}</p>
                }
                <p className="card-text card-icon">
                    <i className="bi bi-calendar4"></i>
                    <span className='card-icon-text'>
                        {getEventDate(event.event_date)}
                    </span>
                </p>
                <p className="card-text card-icon">
                    <i className="bi bi-geo-alt"></i>
                    <CustomLink className='card-icon-text card-icon-link' href={getLocationUrl(event.venue.name)} linkId="EventVenueLink">
                        {event.venue.name}
                    </CustomLink>
                </p>
                {
                    showMoreDetails &&
                    <CustomLink href={getLink('external')} className='btn primary-btn' linkId='ShowMoreDetailsLink'>Show More Details</CustomLink>
                }
            </div>
        </CustomLink>
    );
}

export default OldCard;